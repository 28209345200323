// Body
$body-bg: #ffffff;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 14px;
$line-height-base: 18px;


// Variables configuration
$body-bg:                 white;
$dropdown-bg: transparent;
$form-select-indicator-color: #E6041E;
$form-select-padding-x: 5px;
$form-select-border-width: 0;
$input-btn-focus-box-shadow: 0;
$input-btn-focus-box-shadow: 0;
$input-bg: #F2F2F2;
$input-border-width: 0;
$input-border-radius: 8px;
$input-focus-box-shadow: none;
$input-padding-y: 10px;
$input-padding-x: 30px;
$form-select-bg-size: 20px 20px;
$form-select-box-shadow: none;
$form-select-focus-box-shadow: none;

// colors
$primary-red: #E6041E;
$primary-grey-color: #555555;
$second-grey-color: #707070;
$light-grey-color: #F5F5F5;
$third-grey-color: #989898;
$f2-grey-color: #F2F2F2;

//Paddings
$container-padding-x: 7%;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #E6041E;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$gray: #F2F2F2;