html {
	font-size: 100%;
}

* {
	outline: none;
	@extend %regular;
	font-size: 12px;
	line-height: 16px;
	@include media-breakpoint-up(md) {
		font-size: 14px;
		line-height: 18px;
	}
	color: #555555;
}
.t-bold {
	@extend %bold;
}
body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: $body-bg;
}

a {
	font-size: 12px;
	line-height: 16px;
	@include media-breakpoint-up(md) {
		font-size: 14px;
		line-height: 18px;
	}
	@extend %bold;
	color: #555555;
	text-decoration: underline;
	&:hover {
		color: $red;
	}
}
.form-control:focus,
.form-control {
	margin-bottom: 10px;
	font-size: 12px;
	line-height: 16px;
	@include media-breakpoint-up(md) {
		font-size: 14px;
		line-height: 18px;
	}
	border-radius: 7px;
	background: $gray;
	border: 0px;
	padding: 10px 15px;
	height: initial;
	box-shadow: none;
	&::placeholder {
		color: #989898;
		opacity: 1;
	}
	&::-ms-input-placeholder {
		color: #989898;
	}
	&.bold {
		&::-webkit-input-placeholder {
			@extend %bold;
		}
		&::placeholder {
			@extend %bold;
		}
	}
}
select.form-control {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	/* Some browsers will not display the caret when using calc, so we put the fallback first */
	background: url(../images/arrow.png) $gray no-repeat 98.5% !important; /* !important used for overriding all other customisations */
	background: url(../images/arrow.png) $gray no-repeat calc(100% - 10px) !important; /* Better placement regardless of input width */
}
/*For IE*/
select.form-control::-ms-expand {
	display: none;
}

select.form-control:required:invalid {
	color: #989898;
}
select.form-control.bold:required:invalid {
	@extend %bold;
}

.btn {
	font-size: 12px;
	line-height: 16px;
	@include media-breakpoint-up(md) {
		font-size: 14px;
		line-height: 18px;
	}
	padding: 10px 25px;
	&,
	&:active,
	&:focus {
		box-shadow: none !important;
		border: 0px;
	}
	border-radius: 7px;
	&.btn-bold {
		@extend %bold;
	}
	&.btn-primary {
		&,
		&:active,
		&:focus {
			background-color: $red !important;
			text-decoration: none;
		}
	}
	&.btn-dark {
		&,
		&:active,
		&:focus {
			background-color: #555555 !important;
			text-decoration: none;
		}
	}
}

.invalid-feedback {
	&,
	strong {
		color: $red;
	}
}

//FILE INPUT

.form-group-file {
	position: relative;

	.inputfile {
		display: none;
	}

	.container {
		display: inline-block;
		width: 100%;
	}

	.file-box {
		border-radius: 7px;
		background: $gray;
		color: #989898;
		border: 0px;
		padding: 10px 15px;
		height: initial;
		box-shadow: none;
		font-size: 12px;
		line-height: 16px;
		@include media-breakpoint-up(md) {
			font-size: 14px;
			line-height: 18px;
		}
	}

	.file-button {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: rgb(255, 255, 255);
    border-width: 1px;
    border-style: solid;
    border-color: rgb(173, 181, 189);
    border-image: initial;
}
.custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% center / 50% 50% no-repeat;
}
.custom-control-label::before,
.custom-file-label, .custom-select {
    transition: background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}
.custom-control-label{
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
}
.custom-radio .custom-control-label::before {
    border-radius: 50%;
}
.custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.22rem;
    opacity: 0;
}
.custom-control{
	display: inline-flex;
    margin-right: 1rem;
	position: relative;
    margin-bottom: 0;
    vertical-align: top;
	position: relative;
    z-index: 1;
    min-height: 1.44rem;
    padding-left: 1.5rem;
    -webkit-print-color-adjust: exact;
}
.custom-control-label {
	line-height: 25px;
	color: #989898;
}
.custom-control-label::after,
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    content: " ";
	background: #f2f2f2;
	border-radius: 50%;
	box-shadow: none !important;
	outline: none !important;
}
.custom-control-input:checked ~ .custom-control-label::before {
    content: " ";
	background: $red;
	z-index: 999;
	border: 3px solid #f2f2f2;
	box-shadow: none !important;
	outline: none !important;
}
.custom-control-input:focus ~ .custom-control-label::before {
    content: " ";
	box-shadow: none !important;
}
.display-inline-flex {
	display: inline-flex;
}
.display-flex {
	display: flex;
}


// Fonts
@font-face {
    font-family: 'AzoSans-MediumItalic';
    src: url("../fonts/AzoSans-MediumItalic.otf") format("opentype");
}
@font-face {
    font-family: 'AzoSans-Black';
    src: url("../fonts/AzoSans-Black.otf") format("opentype");
}
@font-face {
    font-family: 'AzoSans-Bold';
    src: url("../fonts/AzoSans-Bold.otf") format("opentype");
}
@font-face {
    font-family: 'AzoSans-Light';
    src: url("../fonts/AzoSans-Light.otf") format("opentype");
}
@font-face {
    font-family: 'AzoSans-LightItalic';
    src: url("../fonts/AzoSans-LightItalic.otf") format("opentype");
}
@font-face {
    font-family: 'AzoSans-Medium';
    src: url("../fonts/AzoSans-Medium.otf") format("opentype");
}
@font-face {
    font-family: 'AzoSans-MediumItalic';
    src: url("../fonts/AzoSans-MediumItalic.otf") format("opentype");
}
@font-face {
    font-family: 'AzoSans-Regular';
    src: url("../fonts/AzoSans-Regular.otf") format("opentype");
}


%black {
    font-family: 'AzoSans-Black';
}
%bold {
    font-family: 'AzoSans-Bold';
}
%light {
    font-family: 'AzoSans-Light';
}
%lightitalic {
    font-family: 'AzoSans-LightItalic';
}
%medium {
    font-family: 'AzoSans-Medium';
}
%mediumitalic {
    font-family: 'AzoSans-MediumItalic';
}
%regular {
    font-family: 'AzoSans-Regular';
}


// Source mixin
@mixin make-container($padding-x: $container-padding-x) {
    width: 100%;
    padding-right: $padding-x;
    padding-left: $padding-x;
    margin-right: auto;
    margin-left: auto;
}

@mixin make-container-mobile() {
    width: 100%;
    padding-right: 4%;
    padding-left: 4%;
    margin-right: auto;
    margin-left: auto;
}
html {
	font-size: 100%;
}

* {
	outline: none;
	@extend %regular;
	color: #555555;
}
.t-bold {
	@extend %bold;
}

// custom container
.myapp-container {
    /* sm */
    @media (min-width: 768px) {
        @include make-container();
    }
    @media (max-width: 767.99px) {
        @include make-container-mobile();
    }
}

.form-group {
    margin-bottom: 1rem;
}
.bold-font {
    font-family: 'AzoSans-Bold';
}
.light-font {
    font-family: 'AzoSans-Light';
}
.lightgrey-back {
    background-color: $light-grey-color;
}

.white-separator {
    background: url(../images/whiteseparator.png) no-repeat center;
    background-size: cover;
    padding-bottom: 60px;
    padding-top: 120px;
    position: relative;
    z-index: 1;
}

a {
    text-decoration: none;
}

p {
    margin-bottom: 0px;
    color: $primary-grey-color;
}

input {
    &:focus
    {
        outline: none;
        box-shadow: none;
    }
}

.vh-100 {
    height: 100vh;
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}

.border-top {
    border-top: 1px solid #555555 !important;
}

.border-bottom {
    border-bottom: 1px solid #555555 !important;
}
.border-right {
    border-right: 1px solid #555555 !important;
}
.border-left {
    border-left: 1px solid #555555 !important;
}

.white-separator-margin {
    padding-top: 80px;
    margin-top: -70px;
}

.btn-fid-red {
    color: white !important;
    background-color: $primary-red;
    border-radius: 8px;
    text-decoration: none;

    &:hover {
        color: white !important;
    }
}

.btn-fid-grey {
    color: white !important;
    background-color: $primary-grey-color;
    border-radius: 8px;
    text-decoration: none;

    &:hover {
        color: white;
    }
}

.btn-read-more {
    font-family: 'AzoSans-Bold';
    font-size: 17px;
    padding: 15px 45px;
    line-height: 20px;
    display: inline-block;
}

.view-more {
    margin-top: 35px;
}

.btn:focus {
    outline: none;
    box-shadow: none;
}

.mt-120 {
    margin-top: 120px;
    @media (max-width: 1199.98px) {
        margin-top: 60px;
    }
}

.mb-120 {
    margin-bottom: 120px;
    @media (max-width: 1199.98px) {
        margin-bottom: 60px;
    }
}

.mt-100 {
    margin-top: 100px;
    @media (max-width: 1199.98px) {
        margin-top: 50px;
    }
}

.mb-100 {
    margin-bottom: 100px;
    @media (max-width: 1199.98px) {
        margin-bottom: 50px;
    }
}

.form-check-input-reverse {
    margin-left: 10px;
}

.form-check-input-reverse {
    margin-left: 10px;
}

input[type="checkbox"] {
    width: 20px;
    height: 20px;
    border: 1px solid;
    border-color: $second-grey-color;
    border-radius: 20px;
    appearance: none;
}
input[type="checkbox"]:checked {
    background-color: $primary-red;
}

.fixed-alert{
	position: fixed;
    top: 50%;
    left: 50%;
    width: 50%;
    margin-left: -25%;
    z-index: 99999;
}
.restaurant-page__container-title .special-bottom-line{
    position: absolute;
    left: 0px;
    bottom:-1px;
    width: 100vw;
    height: 1px;
    background: $second-grey-color;
    left: calc(-50vw + 50%);
}